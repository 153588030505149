import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



export default function Menubar(prop) {

    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedMakeName, setSelectedMakeName] = useState(vehicalDetails ? vehicalDetails.make_name : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedModelName, setSelectedModelName] = useState(vehicalDetails ? vehicalDetails.model_name : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");
    const [selectedVariantName, setSelectedVariantName] = useState(vehicalDetails ? vehicalDetails.variant_name : "");

    const { showMenuSide, setValueMenuSideBar } = prop;

    const [isActive, setIsActvie] = useState('');
    const [isValue, setIsValue] = useState('');

    const hideSideMenueBar = () => {
        setValueMenuSideBar('')
        document.body.classList.remove('home-layout');
    }
    const navigate = useNavigate();


    const showDropWDown = (value) => {
    
        if (isValue == value) {
            
            setIsValue(0)
        } else {
            
            setIsValue(value)
        }

    }
    const emptyLocalStorage = () => {
       
        localStorage.removeItem('setSelectedFitmentType');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setWheelOffsetArray');
        localStorage.removeItem('setColourWheel');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setIsCheckedStock');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        localStorage.removeItem('inProductPage');


        localStorage.removeItem('setWheelSelectedFitmentType');
        localStorage.removeItem('WheelselectedCategories');
        localStorage.removeItem('WheelselectedCategoriesName');
        localStorage.removeItem('WheelselectedBrands');
        localStorage.removeItem('WheelselectedBrandsName');
        localStorage.removeItem('setWheelWheelOffsetArray');
        localStorage.removeItem('setWheelColourWheel');
        localStorage.removeItem('setWheelStockWheel');
        localStorage.removeItem('setWheelDiameterArray');
        localStorage.removeItem('setWheelWidthArray');
        localStorage.removeItem('setWheelPatternArray');
        localStorage.removeItem('setWheelBradArray');
        localStorage.removeItem('setWheelBradNameArray');
        localStorage.removeItem('setWheelcategoryArray');
        localStorage.removeItem('setWheelcategoryNameArray');
        localStorage.removeItem('setWheelModelArray');
        localStorage.removeItem('setWheelMaxPrice');
        localStorage.removeItem('setWheelMinPrice');
        localStorage.removeItem('setWheelFirstValue');
        localStorage.removeItem('setWheelSecondValue');
        localStorage.removeItem('setWheelIsChecked');
        localStorage.removeItem('setWheelIsCheckedStock');
        localStorage.removeItem('setWheelSortingValue');
        localStorage.removeItem('setWheeloffsetCurrentPage');
        localStorage.removeItem('setWheelCurrentPage');

        localStorage.removeItem('setWheelsSelectedFitmentType');
        localStorage.removeItem('setWheelsWheelOffsetArray');
        localStorage.removeItem('setWheelsColourWheel');
        localStorage.removeItem('setWheelsStockWheel');
        localStorage.removeItem('setWheelsDiameterArray');
        localStorage.removeItem('setWheelsWidthArray');
        localStorage.removeItem('setWheelsPatternArray');
        localStorage.removeItem('setWheelsBradArray');
        localStorage.removeItem('setWheelsBradNameArray');
        localStorage.removeItem('setWheelscategoryArray');
        localStorage.removeItem('setWheelscategoryNameArray');
        localStorage.removeItem('setWheelsModelArray');
        localStorage.removeItem('setWheelsMaxPrice');
        localStorage.removeItem('setWheelsMinPrice');
        localStorage.removeItem('setWheelsFirstValue');
        localStorage.removeItem('setWheelsSecondValue');
        localStorage.removeItem('setWheelsIsChecked');
        localStorage.removeItem('setWheelsIsCheckedStock');
        localStorage.removeItem('setWheelsSortingValue');
        localStorage.removeItem('setWheelsoffsetCurrentPage');
        localStorage.removeItem('setWheelsCurrentPage');


        
        localStorage.removeItem('setSwheelsSelectedFitmentType');
        localStorage.removeItem('setSwheelsWheelOffsetArray');
        localStorage.removeItem('setSwheelsColourWheel');
        localStorage.removeItem('setSwheelsStockWheel');
        localStorage.removeItem('setSwheelsDiameterArray');
        localStorage.removeItem('setSwheelsWidthArray');
        localStorage.removeItem('setSwheelsPatternArray');
        localStorage.removeItem('setSwheelsBradArray');
        localStorage.removeItem('setSwheelsBradNameArray');
        localStorage.removeItem('setSwheelscategoryArray');
        localStorage.removeItem('setSwheelscategoryNameArray');
        localStorage.removeItem('setSwheelsModelArray');
        localStorage.removeItem('setSwheelsMaxPrice');
        localStorage.removeItem('setSwheelsMinPrice');
        localStorage.removeItem('setSwheelsFirstValue');
        localStorage.removeItem('setSwheelsSecondValue');
        localStorage.removeItem('setSwheelsIsChecked');
        localStorage.removeItem('setSwheelsIsCheckedStock');
        localStorage.removeItem('setSwheelsSortingValue');
        localStorage.removeItem('setSwheelsoffsetCurrentPage');
        localStorage.removeItem('setSwheelsCurrentPage');
        
        
    }


    const redirectToWheelPage = () => {
        const apiKey = 'qYDXq4NfU3U8faPscasr06bXuKD5RJ1Z';

        if (selectedYear) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.fitmentatlas.com/v1.1/services/Vehicles/smartsizes/?YearId=${selectedYear}&MakeId=${selectedMake}&ModelId=${selectedModel}&ProductType=wheel&SubmodelID=${selectedVariant}`,
                headers: {
                    'FG-ApiKey': apiKey
                }
            };

            axios.request(config)
                .then((response) => {
                    let staggered = false;
                    let fmkWithStaggered = null;
                    // Find the object with isStaggered: true and get its fmk
                    for (const obj of response.data.result) {
                        if (obj.isStaggered === true) {
                            fmkWithStaggered = obj.fmk;
                            staggered = true;
                            break; // Break the loop once we find the first object with isStaggered: true
                        }
                    }

                    // If no object has isStaggered: true, get the fmk from the first object in the array
                    if (fmkWithStaggered === null && response.data.result.length > 0) {
                        fmkWithStaggered = response.data.result[0].fmk;
                    }

                    if (staggered) {
                        navigate(`/wheels-search-by-vehical/staggered?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
                    } else {
                        navigate(`/wheels-search-by-vehical?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });



        } else {
            setTimeout(() => {
                navigate(`/wheels`);
            }, 1000);
        }

    }

    return (
        <div>
            {/* <!-- navigation-section start --> */}
            <section>
                <nav className={`navigation-bar ${showMenuSide}`} id="side-bar">
                    <div className="container">
                        <div className="crose" id="close-bar">
                            <button onClick={hideSideMenueBar}>
                                <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                            </button>
                        </div>
                        <ul className="navigation-list p-0 m-0">
                            <li className='ligt-main has-child' >

                                <span onClick={() => showDropWDown(1)}> Interior  <i className="fa fa-caret-down"></i> </span>
                                <div className={`light-mega mega-menu  ${isValue == 1 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">
                                            <ul className="p-0 menu-list-mega">

                                                <li><a href="/products/22" onClick={emptyLocalStorage} >Gauges & Pods</a></li>
                                                <li><a href="/products/33" onClick={emptyLocalStorage}>Interior Accessories</a></li>
                                                <li><a href="/products/36" onClick={emptyLocalStorage}>Safety</a></li>
                                                <li><a href="/products/1" onClick={emptyLocalStorage}>Floor Mats</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li className="main-menu-1 has-child" >
                                <span onClick={() => showDropWDown(2)}> Exterior  <i className="fa fa-caret-down"></i> </span>
                                <div className={`mega-menu ${isValue == 2 ? 'active-child' : ""}`}>
                                    <div className="col-lg-12 menu-iteam">
                                        <ul className="p-0 menu-list-mega">
                                            <li><a href="/products/14" onClick={emptyLocalStorage}>Bumpers, Grilles & Guards</a></li>
                                            <li><a href="/products/34" onClick={emptyLocalStorage}>Deflectors</a></li>
                                            <li><a href="/products/4" onClick={emptyLocalStorage}>Exterior Styling</a></li>
                                            <li><a href="/products/17" onClick={emptyLocalStorage}>Nerf Bars & Running Boards</a></li>
                                            <li><a href="/products/9" onClick={emptyLocalStorage}>Roofs & Roof Parts</a></li>
                                            <li><a href="/products/7" onClick={emptyLocalStorage}>Tonneau Covers</a></li>
                                            <li><a href="/products/5" onClick={emptyLocalStorage}>Truck Bed Accessories</a></li>
                                            <li><a href="/products/2" onClick={emptyLocalStorage}>Winches & Hitches</a></li>
                                            <li><a href="/products/13" onClick={emptyLocalStorage}>Fender Flares & Trim</a></li>
                                            <li><a href="/products/10" onClick={emptyLocalStorage}>Body Armor & Protection</a></li>
                                            <li><a href="/products/49" onClick={emptyLocalStorage}>Grille Guards & Bull Bars</a></li>
                                            <li><a href="/products/50" onClick={emptyLocalStorage}>Roof Racks & Truck Racks</a></li>
                                            <li><a href="/products/47" onClick={emptyLocalStorage}>Truck Bed Liners</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className='main-menu-1 has-child' >

                                <span onClick={() => showDropWDown(3)}> Performance  <i className="fa fa-caret-down"></i> </span>
                                <div className={`mega-menu ${isValue == 3 ? 'active-child' : ""}`}>
                                    <div className="col-lg-12 menu-iteam">
                                        <ul className="p-0 menu-list-mega">

                                            <li><a href="/products/32" onClick={emptyLocalStorage}>Brakes, Rotors & Pads</a></li>
                                            <li><a href="/products/30" onClick={emptyLocalStorage}>Cooling</a></li>
                                            <li><a href="/products/16" onClick={emptyLocalStorage}>Drivetrain</a></li>
                                            <li><a href="/products/12" onClick={emptyLocalStorage}>Engine Components</a></li>
                                            <li><a href="/products/27" onClick={emptyLocalStorage}>Exhaust, Mufflers & Tips</a></li>
                                            <li><a href="/products/20" onClick={emptyLocalStorage}>Fuel Delivery</a></li>
                                            <li><a href="/products/23" onClick={emptyLocalStorage}>Ignition</a></li>
                                            <li><a href="/products/29" onClick={emptyLocalStorage}>Oils & Oil Filters</a></li>
                                            <li><a href="/products/24" onClick={emptyLocalStorage}>Programmers & Chips</a></li>
                                            <li><a href="/products/21" onClick={emptyLocalStorage}>Suspension</a></li>
                                            <li><a href="/products/25" onClick={emptyLocalStorage}>Air Filters</a></li>
                                            <li><a href="/products/26" onClick={emptyLocalStorage}>Air Intake Systems</a></li>
                                            <li><a href="/products/36" onClick={emptyLocalStorage}>Safety</a></li>
                                            <li><a href="/products/18" onClick={emptyLocalStorage}>Forced Induction</a></li>

                                        </ul>
                                    </div>
                                </div>



                            </li>
                            <li className="ligt-main has-child" >

                                <span onClick={() => showDropWDown(4)}> Lighting  <i className="fa fa-caret-down"></i> </span>
                                <div className={`light-mega mega-menu  ${isValue == 4 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">
                                            <ul className="p-0 menu-list-mega">
                                                <li><a href="/products/11" onClick={emptyLocalStorage}>Lights</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="wheel-mega-main has-child">
                                <span onClick={() => showDropWDown(5)}> Wheels & Tires  <i className="fa fa-caret-down"></i> </span>
                                <div className={`wheel-mega-menu mega-menu  ${isValue == 5 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">

                                            <ul className="p-0 menu-list-mega">
                                                {/* <li onClick={redirectToWheelPage} style={{ cursor: 'pointer' }}>Wheels</li> */}
                                                <li><a href="/wheels" onClick={emptyLocalStorage}>Wheels</a></li>
                                                <li><a href="/tires" onClick={emptyLocalStorage}>Tires</a></li>
                                                <li><a href="/products/8" onClick={emptyLocalStorage}>Wheel and Tire Accessories</a></li>
                                            </ul>

                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li className="ligt-main has-child" >
                                <span onClick={() => showDropWDown(6)}> Parts  <i className="fa fa-caret-down"></i> </span>
                                <div className={`light-mega mega-menu  ${isValue == 6 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">
                                            <ul className="p-0 menu-list-mega">
                                                <li><a href="/products/25" onClick={emptyLocalStorage}>Air Filters</a></li>
                                                <li><a href="/products/26" onClick={emptyLocalStorage}>Air Intake Systems</a></li>
                                                <li><a href="/products/31" onClick={emptyLocalStorage}>Batteries, Starting & Charging</a></li>
                                                <li><a href="/products/32" onClick={emptyLocalStorage}>Brakes, Rotors & Pads</a></li>
                                                <li><a href="/products/30" onClick={emptyLocalStorage}>Cooling</a></li>
                                                <li><a href="/products/34" onClick={emptyLocalStorage}>Deflectors</a></li>
                                                <li><a href="/products/16" onClick={emptyLocalStorage}>Drivetrain</a></li>
                                                <li><a href="/products/12" onClick={emptyLocalStorage}>Engine Components</a></li>
                                                <li><a href="/products/27" onClick={emptyLocalStorage}>Exhaust, Mufflers & Tips</a></li>
                                                <li><a href="/products/20" onClick={emptyLocalStorage}>Fuel Delivery</a></li>
                                                <li><a href="/products/23" onClick={emptyLocalStorage}>Ignition</a></li>
                                                <li><a href="/products/21" onClick={emptyLocalStorage}>Suspension</a></li>
                                                <li><a href="/products/15" onClick={emptyLocalStorage}>Fabrication</a></li>
                                                <li><a href="/products/18" onClick={emptyLocalStorage}>Forced Induction</a></li>
                                                <li><a href="/products/2" onClick={emptyLocalStorage}>Winches & Hitches</a></li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </li>


                            <li className="ligt-main has-child">
                                <span onClick={() => showDropWDown(7)}>Body Parts  <i className="fa fa-caret-down"></i> </span>
                                <div className={`light-mega mega-menu  ${isValue == 7 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">
                                            <ul className="p-0 menu-list-mega">
                                                <li><a href="/products/48" onClick={emptyLocalStorage}>Bumpers</a></li>
                                                <li><a href="/products/14" onClick={emptyLocalStorage}>Bumpers, Grilles & Guards</a></li>
                                                <li><a href="/products/34" onClick={emptyLocalStorage}>Deflectors</a></li>
                                                <li><a href="/products/4" onClick={emptyLocalStorage}>Exterior Styling</a></li>
                                                <li><a href="/products/13" onClick={emptyLocalStorage}>Fender Flares & Trim</a></li>
                                                <li><a href="/products/17" onClick={emptyLocalStorage}>Nerf Bars & Running Boards</a></li>
                                                <li><a href="/products/9" onClick={emptyLocalStorage}>Roofs & Roof Parts</a></li>
                                                <li><a href="/products/7" onClick={emptyLocalStorage}>Tonneau Covers</a></li>
                                                <li><a href="/products/5" onClick={emptyLocalStorage}>Truck Bed Parts</a></li>
                                                <li><a href="/products/49" onClick={emptyLocalStorage}>Grille Guards & Bull Bars</a></li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </li>

                            <li className="ligt-main has-child">
                                <span onClick={() => showDropWDown(8)}>Financing <i className="fa fa-caret-down"></i> </span>
                                <div className={`light-mega mega-menu  ${isValue == 8 ? 'active-child' : ""}`}>
                                    <div className="row">
                                        <div className="col-lg-12 menu-iteam">
                                            <ul className="p-0 menu-list-mega">
                                                <li>
                                                    <a href="/financing/Financing" onClick={emptyLocalStorage}>Financing</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </li>



                            {/* <li><a href="#"> Financing </a></li> */}
                        </ul>
                    </div>
                </nav>
            </section>
            {/* <!-- navigation-section end --> */}
        </div>
    )
}

import { React, useState, useEffect } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { ShimmerTitle } from "react-shimmer-effects";




export default function DetaileSection(prop) {
    const { data, setdata } = prop;
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    // const mainUrl =  "https://www.extremeperformance.com:444";
    const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
    const fitmentType = localStorage.getItem('vehical_fitment');


    // console.log('wheelsDetails++++', vehical_details);

    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const [price, setPrice] = useState(0);
    const [updatedPrice, setnewPrice] = useState(0);
    const sliderImages = data.itemRelatedData && data.itemRelatedData.length > 0 ? data.itemRelatedData[0].s3_images : "";
    const sliderImages2 = data.itemRelatedData && data.itemRelatedData.length > 0 ? data.itemRelatedData[0].images : "";
    const pdfData = data.itemRelatedData && data.itemRelatedData.length > 0 ? data.itemRelatedData[0].pdf : ""; 
    const youtubeData = data.itemRelatedData && data.itemRelatedData.length > 0 ? data.itemRelatedData[0].media : ""; 
    const [valueCart, setValueCart] = useState(1);
    const invnetoryStock = data.stock &&  data.stock  > 0 ? data.stock : 0;    
    const [cartData, setCartData] = useState([]);
    const [showEnquiryModel, setShowEnquiryModel] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [affirmLoad, setAffirmLoad] = useState(false);
    const brandlogo = data.brandData && data.brandData.length > 0 ? data.brandData[0].logo : "";



    const year = vehical_details ? vehical_details.year : "";
    const make_name = vehical_details ? vehical_details.make_name : "";
    const model_name = vehical_details ? vehical_details.model_name : "";
    const variant_name = vehical_details ? vehical_details.variant_name : "";
    const fitment = fitmentType ? fitmentType : "";


    let pdfFiles = [];
    let pdfFilesLinks = [];
    let YoutubeFilesLinks = [];
    let imageArray = [];
    let otherFiles = [];
    const product_id = data.itemsData[0].item_id;
    let product_name = data.itemsData[0].part_description;
    if (year) {
        if (fitment) {
            product_name = `${data.itemsData[0].part_description}\n("${year} , ${make_name}  ,${model_name}  , ${variant_name} , ${fitment}")`;

        } else {
            product_name = `${data.itemsData[0].part_description}\n("${year}  ,${make_name} , ${model_name}  , ${variant_name}")`;

        }

    }

    const part_number = data.itemsData[0].part_number;
    const product_quantity = valueCart;
    const category_type = 'acc';
    const surcharge = data.itemCostData[0] && data.itemCostData[0].surcharge ? data.itemCostData[0].surcharge : 0;

  

    useEffect(() => {
        const affirmConfig = {
            public_api_key: "Z6PMLBXW7DT8S738",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
        };

        const initializeAffirm = () => {
            const { affirm } = window;

            const priceUpdateEventHandler = (newPrice) => {
             //   console.log("priceUpdateEventHandler");
                // changePriceHandler(newPrice);
            }

            const updateAffirmPromos = (newPrice) => {
                document.getElementById('affirmProductModal').setAttribute('data-amount', newPrice);
                document.getElementById('affirmLearnMore').setAttribute('data-amount', newPrice);
                affirm.ui.refresh();
            }

            affirm.ui.ready(() => {
                const newPrice = 699;

                // Listen for the Affirm Learn More modal close event
                affirm.events.on('learnMore:close', () => {
                 //   console.log('Affirm Learn More modal closed');
                });

                // Add this script to an existing callback that fires when the price updates
                priceUpdateEventHandler(newPrice);

                // Update Affirm promos with the initial price
                updateAffirmPromos(newPrice);

            });
        };

        // Load Affirm script
        const loadAffirmScript = () => {
            const script = document.createElement('script');

            // console.log(script, " ============script2")

            if (typeof script != undefined) {
                script.async = true;
                script.src = affirmConfig.script;
                script.onload = initializeAffirm;
                document.body.appendChild(script);
                setAffirmLoad(true)
            }

        };

        // Run the initialization after 5 seconds
        setTimeout(loadAffirmScript, 8000);

        return () => {
            // Cleanup: Remove Affirm script when component is unmounted
            //in furute if we need this use find


            // if(script ){
            //     document.body.removeChild(script);
            // }


        };
    }, []);

    // useEffect(() => {
    //     const priceData = data.itemCostData[0];
    //     if (priceData) {
    //         if (priceData.map > 0) {
    //             setPrice(parseFloat(priceData.map) + (parseFloat(data.itemCostData[0].surcharge) || 0));
    //         } else if (priceData.jobber > 0) {
    //             setPrice(parseFloat(priceData.jobber) + (parseFloat(data.itemCostData[0].surcharge) || 0));
    //         } else if (priceData.retails > 0) {
    //             setPrice(parseFloat(priceData.retails) + (parseFloat(data.itemCostData[0].surcharge) || 0));
    //         } else {
    //             setPrice(0);
    //         }
    //     } else {
    //         setPrice(0);
    //     }
    // }, [data]);

    useEffect(() => {
        const priceData = data.itemCostData[0];
    
        if (priceData) {
            const cost = parseFloat(priceData.cost);
            const map = parseFloat(priceData.map);
            const retail = parseFloat(priceData.retails);
            const jobber = parseFloat(priceData.jobber);
            const surcharge = parseFloat(priceData.surcharge) || 0;
            const corecharge = parseFloat(priceData.corecharge) || 0;
    
            if (map > 0) {
                setPrice(map + surcharge + corecharge);
            } 
            
            else if (retail > 0) {
                const calculatedRetailPrice = retail - (0.1 * retail);
                if (cost * 1.1 < calculatedRetailPrice) {
                    setPrice(calculatedRetailPrice + surcharge + corecharge);
                } else {
                    setPrice(cost * 1.1 + surcharge + corecharge);
                }
            }
            
            else if (jobber > 0) {
                if (jobber > cost * 1.1) {
                    setPrice(jobber + surcharge + corecharge);
                } else {
                    setPrice(cost * 1.1 + surcharge + corecharge);
                }
            } 
            
            
            
            else {
                setPrice(cost * 1.1 + surcharge + corecharge);
            }
        } else {
            setPrice(0);
        }
    }, [data]);

    useEffect(() => {
        const priceData = data.itemCostData[0];
        // console.log("priceData++++++++++++++", priceData)
        if (priceData) {
        
            if (parseFloat(priceData.retails) > parseFloat(priceData.map) || parseFloat(priceData.retails) > parseFloat(priceData.jobber) ) {
            //    console.log("priceData++++++++++++++", priceData.retails)
                setnewPrice(priceData.retails);
            } else {
                // console.log("priceData------------", priceData.retails)
                setnewPrice(0); 
                
            }
        } else {
            
            setnewPrice(0);
        }
    }, [data]);
    

        if(pdfData){
            const pdfObjects = JSON.parse(pdfData);
            // console.log("pdfLinks++++++++",pdfObjects)
            pdfFilesLinks.push(pdfObjects);
        }else{
            // console.log("no ++++ pdfLinks++++++++")

        }




        // if (youtubeData) {
        //   try {
        //     const youtubeObjects = JSON.parse(youtubeData);
        //     console.log("youtubeObjects++++++++", youtubeObjects);
        
        //     Object.values(youtubeObjects).forEach(link => {
        //       const videoId = getYoutubeVideoId(link);
        //       if (videoId) {
        //         YoutubeFilesLinks.push({ videoId });
        //       } else {
        //         console.warn("Invalid YouTube link");
        //       }
        //     });
        //   } catch (error) {
        //     console.error("Error parsing YouTube data:", error);
        //   }
        // } else {
        //   console.log("No YouTube data available");
        // }
        

        
        
    if (sliderImages) {
        imageArray = sliderImages.split(',');
        imageArray.forEach(url => {
            const extension = url.split('.').pop();
            if (extension.toLowerCase() === 'pdf') {
                pdfFiles.push(S3URL + url);
            }
            else {
                otherFiles.push(S3URL + url);
            }
        });
    } else if (sliderImages2) {
        imageArray = sliderImages2.split(',');
        imageArray.forEach(url => {
            const extension = url.split('.').pop();
            if (extension.toLowerCase() === 'pdf') {
                pdfFiles.push(url);
            } else {
                otherFiles.push(url);
            }
        });

    } else {
        if (!brandlogo) {
            otherFiles.push("/images/no-image-found.png");
        } else {
            otherFiles.push(brandlogo);
        }
    }

    // console.log('otherFiles', otherFiles)
    const apiAddtoCart = async () => {

        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: otherFiles[0],
                    product_id: product_id,
                    product_name: product_name,
                    product_price: price,
                    product_quantity: valueCart,
                    category_type: category_type,
                    total_quantity: invnetoryStock,
                    part_number: part_number,
                    surcharge: surcharge

                }
            };
            axios
                .request(config)
                .then((response) => {
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    localStorage.setItem('cartItemsArray', JSON.stringify(response.data.cart));

                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };




    const increaseValue = () => {

        if (valueCart < invnetoryStock) {
            setValueCart(valueCart + 1);

        }

    };

    const decreaseValue = () => {
        if (valueCart > 1) {
            setValueCart(valueCart - 1);

        }
    }

    const addItemstoCaart = () => {
        const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
        const itemId = data.itemCostData[0].item_id;
        const existingValue = cartItems[itemId] || 0;
        const updatedValue = existingValue + valueCart;
        // Check if the updated value exceeds the stockInventory
        if (updatedValue > invnetoryStock) {
            toast.info("Cannot add more items than available in stock!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        cartItems[itemId] = updatedValue;
        localStorage.setItem('totalCartItems', JSON.stringify(cartItems));
        setCartData(cartItems);
        apiAddtoCart();
        setdata(cartItems);


    }
    function getYoutubeVideoId(url) {
        const regex = /[?&]([^=#]+)=([^&#]*)/g;
        let match;
        while ((match = regex.exec(url))) {
          if (match[1] === 'v') {
            return match[2];
          }
        }
        return null;
      }
    function downloadFile() {
        const fileUrl = S3URL + "images/523384/fffd7d890e3072547e0edde7b00be0ef.PDF";
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "filename.pdf";
        link.click();
    }


    const handleEnquiryModel = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters from input
        const cleanedInput = e.target.value.replace(/\D/g, '');
        setPhone(cleanedInput);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };


    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g. sending the inquiry data to a backend API
        // You can access email, phone, and comments state variables here
        // Reset form fields if needed
        try {

            try {
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/submit-inquiry`,
                    credentials: 'include', // Send cookies with the request
                    withCredentials: true,
                    params: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        comments: comments,

                    }
                };
                axios
                    .request(config)
                    .then((response) => {
                        toast.success("Submitted successfully!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        // Reset form fields and close the modal
                        setEmail('');
                        setPhone('');
                        setComments('');
                        handleClose();

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            } catch (error) {
                console.error('Error fetching data:', error);

            }

        } catch (error) {
            console.error('Error submitting inquiry:', error);
        }


    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // If the input is empty, set the value to an empty string
        if (inputValue === '') {
            setValueCart(1);
            return;
        }

        // Parse the input value as an integer
        const parsedValue = parseInt(inputValue, 10);

        // Check if the parsed value is a valid number
        if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= invnetoryStock) {
            setValueCart(parsedValue);
        }
    };

    let actualPrice = price;
    //5% off
    let discount = 0.05; // 5% discount
    let discountedPrice = actualPrice - (actualPrice * discount);
    // console.log("Actual Price: $" + actualPrice);
    // console.log("5% Off Price: $" + discountedPrice);

    return (
        <>
            <div className="main-product-data">

                <div className="product-name-details">

                    <span className='tag_name'>{data.itemsData[0].brand}</span>
                    <h2>{data.itemsData[0].part_description}</h2>
                    <span className='prod_cat'>{data.itemsData[0].product_name}</span>
                    <div className="stock mb-3">
                        <h3 className="m-0">Stock</h3>
                        <h4 className="m-0">
                        {
                                data.stock &&  data.stock > 0  && price > 0 ?
                                    <span className=''><i className="fa fa-circle me-1"></i> In stock ( {data.stock} )</span>
                                    :
                                    <span className='text-danger'><i className="fa fa-circle me-1"></i>Out of Stock</span>
                            }

                        </h4>
                        <h5 className="m-0">Part Number: <span>{data.itemsData[0].part_number}</span></h5>

                    </div>
                            {
                                data.stock && data.stock > 0  && price > 0?

                                                <div id="prices" style={{ height: '25px' }}>
                                                    {
                                                        !affirmLoad ?
                                                            <ShimmerTitle line={1} gap={10} variant="primary" />
                                                            :

                                                            <p id="learn-more" className="affirm-as-low-as" data-amount={`${data.itemsData[0].price ? data.itemsData[0].price * 100 : price * 100}`} data-affirm-color="blue" data-learnmore-show="true" data-page-type="product"> </p>

                                                    }
                                                </div>:""
                            }
                    <div className='deal_of_the_day'>

                    </div>
                    <div className="product-deastil-preice deal_of_the_day">
                        <div className='price'>
                            <h2>
                                {updatedPrice <= 0 || updatedPrice <= price
                                    ? `$${parseFloat(price).toFixed(2)}`
                                    : (
                                        <>
                                            {`$${parseFloat(price).toFixed(2)}`}
                                            {price > 0 && <s>MSRP&nbsp;${parseFloat(updatedPrice).toFixed(2)}</s>}
                                        </>
                                    )
                                }
                            
                            </h2>
                        </div>
                        <div className='free-shippping'>
                            {
                                price >= 100 ? (
                                    <div className='free'>
                                        <i className="fa fa-truck" aria-hidden="true"></i>
                                        <div className='refer'>Free Shipping <p>(Refer Terms & Conditions)</p></div>
                                    </div>
                                ) : (
                                    ""
                                )
                            }
                        </div>

                        {/* <button className="saving">Save 8%</button> */}
                    </div>
                    {/* <h3 className="txt-quainty">Quantity</h3> */}
                    <div className="quantity">
                        {
                            invnetoryStock && price > 0 ?
                                <>
                                    <div className="incres">
                                        <div className="value-button" id="decrease" onClick={decreaseValue}>
                                            <i className="fa fa-minus"></i>
                                        </div>
                                        <input type="text" id="number" value={valueCart} onChange={handleInputChange} />
                                        <div className="value-button" id="increase" onClick={increaseValue}>
                                            <i className="fa fa-plus"></i>
                                        </div>
                                    </div>

                                    <div className="cart">
                                        <button className="" onClick={addItemstoCaart}><svg className="me-1" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.982759 1.96567H4.07847L5.65092 10.9827C5.7738 11.6214 6.48624 12.2849 7.10048 12.2849H17.6654C18.3288 12.2849 18.8201 11.6952 18.8938 11.1056L21.3263 1.42519C21.3508 1.35149 21.3508 1.2778 21.3508 1.17942C21.3508 0.516003 20.8347 0.00013155 20.1223 0.00013155H7.91125C7.37073 0.00013155 6.92856 0.442295 6.92856 0.982824C6.92856 1.52335 7.37073 1.96552 7.91125 1.96552H19.1887L18.8202 3.43977H8.59927C8.05874 3.43977 7.61658 3.88194 7.61658 4.42247C7.61658 4.963 8.05874 5.40516 8.59927 5.40516H18.3042L17.9356 6.87955H9.1397C8.59917 6.87955 8.15701 7.32171 8.15701 7.86224C8.15701 8.40277 8.59917 8.84493 9.1397 8.84493H17.4442L17.0757 10.3192H7.54275L5.87211 0.810794C5.79842 0.343929 5.38077 0 4.9139 0H0.982693C0.442163 0 0 0.442163 0 0.982692C0 1.52322 0.442163 1.96556 0.982693 1.96556L0.982759 1.96567Z" fill="white" />
                                            <path d="M16.0444 18.4272C17.4449 18.4272 18.575 17.2725 18.575 15.8474C18.575 14.5207 17.6168 13.4396 16.3639 13.2923H16.3393H16.2656H16.1428H8.59993H8.55074H8.45253C7.05198 13.2923 5.92188 14.447 5.92188 15.8721C5.9217 17.297 7.05198 18.4272 8.42783 18.4272C9.80368 18.4272 10.9585 17.2725 10.9585 15.8474C10.9585 15.6263 10.934 15.4297 10.8848 15.2332H13.612C13.5628 15.4297 13.5383 15.6263 13.5383 15.8474C13.5383 17.2724 14.6686 18.4272 16.0445 18.4272H16.0444ZM16.0444 15.2332C16.3391 15.2332 16.6094 15.5034 16.6094 15.8474C16.6094 16.1914 16.3636 16.4616 16.0444 16.4616C15.7249 16.4616 15.4793 16.1914 15.4793 15.8474C15.4791 15.5034 15.7494 15.2332 16.0444 15.2332ZM8.42781 16.4616C8.13303 16.4616 7.86276 16.1914 7.86276 15.8474C7.86276 15.5034 8.10852 15.2332 8.42781 15.2332C8.74727 15.2332 8.99287 15.5034 8.99287 15.8474C8.99287 16.1914 8.74727 16.4616 8.42781 16.4616Z" fill="white" />
                                        </svg>
                                            Add to Cart</button>
                                    </div>
                                    {/* <div className="financeButtonMain"> */}
                                    <div className="Finance">
                                        <a href='https://consumer.snapfinance.com/#/?mid=490293126' target='_blank'>
                                            <button>Click to Finance</button>
                                        </a>
                                    </div>
                                    {/* </div>   */}
                                </>
                                : <>
                                    <div className="cart">

                                        Out of stock !

                                    </div>
                                    <div className="Finance">
                                        <button className="Finance" title='Item out of stock' onClick={handleEnquiryModel}>
                                            Click for Enquiry</button>
                                    </div>
                                </>
                        }

                    </div>
                </div>

                {/* <div className='categoryNameDetailsPage'>
                    <h5>
                        {data.itemsData[0].category_name}
                    </h5>
                </div> */}
                <div className='subCategoryNameDetailsPage'>
                    <span className='sub_cat_name'>
                        {data.itemsData[0].subcategory_name}
                    </span>
                </div>


                <div className="producDescription">
                {data  && data.itemRelatedData[0] && data.itemRelatedData[0].description ? (
                    <div>
                    <strong>About this product </strong><br></br>
                    <p>
                        {data && data.itemRelatedData[0] ? data.itemRelatedData[0].description : ""}
                    </p>
                    </div>
                    ):(
                        ""
                    )}
               </div>

                
                {pdfFilesLinks.length > 0 ? (
                <div className='add-info'>
                <strong  style={{fontSize: '21px'}}>Additional Information</strong>
                <hr style={{marginTop:'5px',marginBottom:'10px'}}/>
                {pdfFilesLinks.map((pdfObject, index) => (
                Object.entries(pdfObject).map(([key, link]) => (
                    <div className='information' key={index + key}>
                        <div className='name'>
                            <p>{key} PDF</p>
                        </div>
                        <div className='download-btn'>
                            <a href={link} download className="download-button" style={{fontSize: 'medium',float: 'right',color: '#E81C2E' , fontWeight: 'bold'}} target="_blank">
                                <img src ="../icons/Download.svg" alt="Download" style = {{width:'20px', height:'20px', marginRight:'5px'}} />
                                Download
                            </a>
                        </div>
                    </div>
                ))
                ))}
                </div>
                ):(
                    ""
                )}
                    {/* {YoutubeFilesLinks.length > 0 ? (
                    <div>
                        <strong>Watch Videos</strong><br />
                        {YoutubeFilesLinks.map(({ index, videoId }) => (
                        <div key={index}>
                            <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/watch?v=${videoId}`}
                            title={`YouTube Video ${index + 1}`}
                            allowFullScreen
                            ></iframe>
                        </div>
                        ))}
                    </div>
                    ):(
                        ""
                    )} */}
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Your Enquiry About : {data.itemsData[0].product_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className='enquiry-form-class'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="firstName"
                                        placeholder="Enter first name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter last name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group controlId="comments">
                                    <Form.Label>Year, Make, Model & Comments*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter your comments"
                                        value={comments}
                                        onChange={handleCommentsChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-end'>
                                <Form.Group controlId="comments">
                                    <Button variant="danger" type="submit">
                                        Submit
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}

import { React } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function TopBar(prop) {
    const navigate = useNavigate();

    const { isHidden } = prop;
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const paramName = params.get('redirected');
    if (paramName == 1) {
        deleteCartApi();
    }

    const goToFinance = () => {
        navigate(`/financing`);

    }

    return (
        <div>
            <div className={isHidden ? 'loader-line hide' : 'loader-line'}></div>
            <section className="annusment-section">

                <div className="container mobile-mar">
                    <div className="main-annusment-bar">
                        <div className="inner-annusment-text">
                            <h1 className="m-0">
                                No credit financing available! 100 days flexible payment options!
                            </h1>
                        </div>
                        <div className="inner-annusment-button">
                            <button onClick={goToFinance}>Apply Now</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
const deleteCartApi = async () => {

    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${mainUrl}/api/clear`,
            credentials: 'include', // Send cookies with the request
            withCredentials: true,
            params: {


            }
        };
        axios
            .request(config)
            .then((response) => {

                localStorage.removeItem('totalCartItems');
            })
            .catch((error) => {
                console.log(error);

            });
    } catch (error) {
        console.error('Error clearing data:', error);

    }
};
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import TiresItemscard from "../../components/Cards/tires-staggered-card";
import TiresItemscardNonStaggered from "../../components/Cards/tires-non-staggered-card";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';


export default function Index() {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));

  const queryParams = new URLSearchParams(window.location.search);
  const year = queryParams.get('year');
  const make = queryParams.get('make');
  const model = queryParams.get('model');
  const variant = queryParams.get('variant');


  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(false);
  const [dataTires, setDataTires] = useState("");
  const [isLoadingWheels, setIsLoadingWheels] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(18000);
  const [sortingValue, setSortingValue] = useState(1);
  const MAX_PAGES_DISPLAY = 10;

  const [stockWheel, setStockWheel] = useState("");
  const [diameterArray, setDiameterArray] = useState("");
  const [widthArray, setWidthArray] = useState([]);
  const [brandArray, setBradArray] = useState([]);
  const [brandNameArray, setBradNameArray] = useState([]);
  const [categoryArray, setcategoryArray] = useState([]);
  const [categoryNameArray, setcategoryNameArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [ratioArray, setratioArray] = useState([]);
  const [warranty, setwarranty] = useState("");
  const [runflat, setRunFlat] = useState("");
  const [studdable, setstuddable] = useState("");
  const [tiresizes, settiresizes] = useState("one");
  const [speedRatingArray, setSpeedRatingArray] = useState([]);
  const [speedLoadRange, setSpeedLoadRange] = useState([]);
  const [plyArray, setPlyArray] = useState([]);
  const [sideWallArray, setSideWallArray] = useState([]);
  const [milageRatingArray, setMilageRatingArray] = useState([]);
  const [staggeredDiameter, setStaggeredDiameter] = useState("");





  const [openDiameter, setopenDiameter] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openWidth, setOpenWidth] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openRatio, setopenRatio] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openSpeedRating, setOpenSpeedRating] = useState(false);
  const [openLoadRating, setOpenLoadRating] = useState(false);
  const [openSideWall, setOpenSideWall] = useState(false);
  const [openPly, setOpenPly] = useState(false);
  const [openRunFlat, setOpenRunFlat] = useState(false);
  const [openStuddable, setOpenStuddable] = useState(false);
  const [openTireSizes, setOpenTireSizes] = useState(false);
  const [openWarranty, setOpenWarranty] = useState(false);
  const [openMilageRating, setOpenMilageRating] = useState(false);


  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(18000);

  const [filterClass, setFilterClass] = useState('');
  const [showMenuSide, setShowMenuSide] = useState("");
  const [isCheckedStock, setIsCheckedStock] = useState(true);
  const [maxPriceDefault, setMaxPriceDefault] = useState(18000);
  const [isChecked, setIsChecked] = useState(true);
  const [displayedProducts, setdisplayedProducts] = useState('');
  const [isdata, setIsdata] = useState(true);


  const PRODUCTS_PER_PAGE = 28;
  // const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  // const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);


  // Calculate the total number of pages based on loaded data

  // Calculate the indices of products to be displayed on the current page
  const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
  const endIndex = Math.min(startIndex + PRODUCTS_PER_PAGE, totalProducts);


  useEffect(() => {
    fetchTiresData();
  }, [currentPage, sortingValue, firstValue, secondValue, diameterArray, widthArray, brandArray, modelArray, stockWheel,isChecked ,isCheckedStock, categoryArray, ratioArray, warranty, runflat, studdable, speedRatingArray, speedLoadRange, plyArray, sideWallArray, milageRatingArray, tiresizes,staggeredDiameter]);

  useEffect(() => {
    if (dataTires && dataTires.data ) {
      const totalProducts = dataTires.data.length;
     // console.log('totalProducts',totalProducts);
      setTotalPages(Math.ceil(totalProducts / PRODUCTS_PER_PAGE));

      const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
      const endIndex = Math.min(startIndex + PRODUCTS_PER_PAGE, totalProducts);
      setdisplayedProducts(dataTires.data.slice(startIndex, endIndex));

      // setIsLoadingWheels(false);
    }
  }, [dataTires, currentPage]);


  const fetchTiresData = async () => {    
    document.body.classList.add('full-screen-loader');
    setIsLoadingWheels(true);
    const limit = 28;
    const offset = (currentPage - 1) * limit;
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        credentials: 'include', // Send cookies with the request
        withCredentials: true,
        url: `${mainUrl}/api/tires/getAllTiresDataForVehicleStaggered?diameter=${diameterArray}&brand=${brandArray}&width=${widthArray}&limit=${limit}&offset=${offset}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&stockWheel=${stockWheel}&style=${modelArray}&isCheckedStock=${isCheckedStock}&categoryArray=${categoryArray}&ratioArray=${ratioArray}&warranty=${warranty}&runflat=${runflat}&studdable=${studdable}&speedRatingArray=${speedRatingArray}&speedLoadRange=${speedLoadRange}&plyArray=${plyArray}&sideWallArray=${sideWallArray}&milageRatingArray=${milageRatingArray}&tiresizes=${tiresizes}&year=${year}&make=${make}&model=${model}&variant=${variant}&isStagered=${isChecked}&staggeredDiameter=${staggeredDiameter}`,
      };
      axios.request(config)
        .then((response) => {
          setDataTires(response.data);
          if(response.data.status == 500){
            setIsdata(false);
    
           }
          setIsLoadingWheels(false);
          // setTotalPages(response.data.totalPages);
          setTotalPages(response.data.totalPages);
          setTotalProducts(response.data.count);
          setdisplayedProducts(response.data.data.slice(startIndex, endIndex));

          window.scrollTo({ top: 400, behavior: 'smooth' });
          setIsHidden(true);
          document.body.classList.remove('full-screen-loader');
          // setMaxPriceDefault((Math.ceil(response.data.maximumPrice)) + 1);
          // setMinPrice(Math.floor(response.data.minimumPrice));
          // setMaxPrice(Math.ceil(response.data.maximumPrice));

          // const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
          // const endIndex = Math.min(startIndex + PRODUCTS_PER_PAGE, totalProducts);
          // setdisplayedProducts(response.data.data.slice(startIndex, endIndex));
    

        })
        .catch((error) => {
          setIsdata(false);

          setIsHidden(true);
          document.body.classList.remove('full-screen-loader');
          //navigate(`/error`);

        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingWheels(false);
      setIsHidden(true);
      document.body.classList.remove('full-screen-loader');
      // navigate(`/error`);

    }
  };





  const handleSorting = (event) => {
    const sortingValue = event.target.value;
    // console.log(sortingValue);
    setSortingValue(sortingValue);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setdisplayedProducts(dataTires && dataTires.data ? dataTires.data.slice(startIndex, endIndex) : "");
    window.scrollTo({ top: 400, behavior: 'smooth' });
  };

  const getPageNumbers = () => {
    const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
    const middlePage = Math.ceil(totalDisplayPages / 2);
    let startPage = Math.max(1, currentPage - middlePage + 1);

    if (startPage + totalDisplayPages > totalPages) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
  };








  const handleDiameterCheckboxChange = (event) => {
    setIsHidden(true);
    // const sdiameter = event.target.value;
    // if (diameterArray.includes(sdiameter)) {
    //   setDiameterArray(diameterArray.filter((id) => id !== sdiameter));
    // } else {
    //   setDiameterArray([...diameterArray, sdiameter]);
    // }

    const selectedOption = event.target.value;
  //  console.log('handleSelectChangeFront', selectedOption);
    if (selectedOption == staggeredDiameter) {
      setDiameterArray("");
    } else {
      setDiameterArray(selectedOption);
    }

  };
  const handleWidthCheckboxChange = (event) => {
    setIsHidden(true);
    const sWidth = event.target.value;
    if (widthArray.includes(sWidth)) {
      setWidthArray(widthArray.filter((id) => id !== sWidth));
    } else {
      setWidthArray([...widthArray, sWidth]);
    }
  };
  const handleBrandCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (brandArray.includes(sBrand)) {
      setBradArray(brandArray.filter((id) => id !== sBrand));
    } else {
      setBradArray([...brandArray, sBrand]);
    }
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = brandNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      setBradNameArray(
        brandNameArray.filter((obj) => obj.id !== sBrand)
      );
    } else {
      setBradNameArray([...brandNameArray, categoryObject]);
    }
  };

  const handleCategoryCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (categoryArray.includes(sBrand)) {
      setcategoryArray(categoryArray.filter((id) => id !== sBrand));
    } else {
      setcategoryArray([...categoryArray, sBrand]);
    }
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = categoryNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      setcategoryNameArray(
        categoryNameArray.filter((obj) => obj.id !== sBrand)
      );
    } else {
      setcategoryNameArray([...categoryNameArray, categoryObject]);
    }
  };

  const handleModelCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (modelArray.includes(sModle)) {
      setModelArray(modelArray.filter((id) => id !== sModle));
    } else {
      setModelArray([...modelArray, sModle]);
    }
  };

  const handleSpeedRatingCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (speedRatingArray.includes(sModle)) {
      setSpeedRatingArray(speedRatingArray.filter((id) => id !== sModle));
    } else {
      setSpeedRatingArray([...speedRatingArray, sModle]);
    }
  };


  const handleLoadRangeCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (speedLoadRange.includes(sModle)) {
      setSpeedLoadRange(speedLoadRange.filter((id) => id !== sModle));
    } else {
      setSpeedLoadRange([...speedLoadRange, sModle]);
    }
  };



  const handleRatioCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (ratioArray.includes(sModle)) {
      setratioArray(ratioArray.filter((id) => id !== sModle));
    } else {
      setratioArray([...ratioArray, sModle]);
    }
  };




  const handlePlyCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (plyArray.includes(sModle)) {
      setPlyArray(plyArray.filter((id) => id !== sModle));
    } else {
      setPlyArray([...plyArray, sModle]);
    }
  };




  const handleWarrentyCheckboxChange = (event) => {

    const newValue = event.target.value;
    if (warranty === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setwarranty(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setwarranty(newValue);
    }

  };

  const handleSideWallCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (sideWallArray.includes(sModle)) {
      setSideWallArray(sideWallArray.filter((id) => id !== sModle));
    } else {
      setSideWallArray([...sideWallArray, sModle]);
    }
  };


  const handleMilageRatingCheckboxChange = (event) => {
    setIsHidden(true);
    const value = event.target.value;
    if (milageRatingArray.includes(value)) {
      setMilageRatingArray(milageRatingArray.filter((id) => id !== value));
    } else {
      setMilageRatingArray([...milageRatingArray, value]);
    }
  };


  const handleRunFlatCheckboxChange = (event) => {
    // setIsHidden(true);
    // setRunFlat(event.target.value)


    const newValue = event.target.value;
    if (runflat === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setRunFlat(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setRunFlat(newValue);
    }

  };


  const handleStuddableCheckboxChange = (event) => {
    // setIsHidden(true);
    // setstuddable(event.target.value)
    const newValue = event.target.value;
    if (studdable === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setstuddable(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setstuddable(newValue);
    }

  };

  const handleTireSizesCheckboxChange = (event) => {
    // setIsHidden(true);
    // setstuddable(event.target.value)
    const newValue = event.target.value;
    if (tiresizes === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      settiresizes(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      settiresizes(newValue);
    }

  };





  const clearAllFilters = () => {
    setBradArray([]);
    setcategoryArray([]);
    setBradNameArray([]);
    setcategoryNameArray([]);
    setratioArray([]);
    setDiameterArray("");
    setWidthArray([]);
    setBradNameArray([]);
    setcategoryArray([]);
    setModelArray([]);
    setratioArray([]);
    setwarranty("");
    setRunFlat("");
    setstuddable("");
    setSpeedRatingArray([]);
    setSpeedLoadRange([]);
    setPlyArray([]);
    setSideWallArray([])
    setMilageRatingArray([])
    setStaggeredDiameter("");


  }
  const handleRemoveDiameter = (id) => {
    setDiameterArray("");
  };
  const handleRemoveWidth = (width) => {
    const updatedCategories = widthArray.filter((id) => id !== width);
    setWidthArray(updatedCategories);
  };
  const handleRemoveModel = (width) => {
    const updatedCategories = modelArray.filter((id) => id !== width);
    setModelArray(updatedCategories);

  };
  const handleRemoveBrand = (width) => {
    const updatedCategories = brandArray.filter((id) => id !== width);
    setBradArray(updatedCategories);
    const updatedBrandNames = brandNameArray.filter((name) => name.id !== width);
    setBradNameArray(updatedBrandNames);

  };

  const handleRemoveRatio = (ratio) => {
    const updatedCategories = ratioArray.filter((id) => id !== ratio);
    setratioArray(updatedCategories);


  };

  const handleRemoveLoadRange = (loadrange) => {
    const updatedCategories = speedLoadRange.filter((id) => id !== loadrange);
    setSpeedLoadRange(updatedCategories);


  };
  const handleRemovedSpeedRating = (speedRating) => {
    const updatedCategories = speedRatingArray.filter((id) => id !== speedRating);
    setSpeedRatingArray(updatedCategories);


  };
  const handleRemovedPly = (ply) => {
    const updatedCategories = plyArray.filter((id) => id !== ply);
    setPlyArray(updatedCategories);


  };



  const handleRemovedSideWall = (ply) => {
    const updatedCategories = sideWallArray.filter((id) => id !== ply);
    setSideWallArray(updatedCategories);


  };
  const handleRemovedMilageRating = (ply) => {
    const updatedCategories = milageRatingArray.filter((id) => id !== ply);
    setMilageRatingArray(updatedCategories);


  };






  const showFiltersClick = () => {
    setFilterClass('active-filter')
    document.body.classList.add('home-layout')

  }
  const hideFilterClass = () => {
    setFilterClass('')
    document.body.classList.remove('home-layout')
  }

  const setValueMenuSideBar = (value) => {
    setShowMenuSide(value)
  }




  // const PRODUCT_PER_PAGE = 28;
  // const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  // const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);
  // const optionsAvailbility = [
  //   { value: "zero", label: "ALL" },
  //   { value: "one", label: "1" },
  //   { value: "two", label: "2" },
  //   { value: "three", label: "3" },
  //   { value: "four", label: "4+" },

  // ];
  const [cartItems, setCartItems] = useState("");

  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');

    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartItems(sum);
    }
  }, []);

  const handleChangeStockAvail = () => {
    setIsCheckedStock(!isCheckedStock)
  }

  const handleChangeSlider = (event, newValue) => {
    setFirstValue(newValue[0]);
    setSecondValue(newValue[1]);

    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  const mileageRating = [
    20000, 25000, 30000,
    35000, 40000, 45000,
    50000, 55000, 60000,
    62000, 65000, 70000,
    75000, 80000, 85000,
    90000
  ];

  const handleStaggeredChange = (event) => {
    setIsChecked(!isChecked)
    setCurrentPage(1);
  }

  const handleStaggeredDiameterCheckboxChange = (event) => {
    const selectedOption = event.target.value;
    // console.log('handleSelectChangeFront', selectedOption);
    if (selectedOption == staggeredDiameter) {
      setStaggeredDiameter("");
    } else {
      setStaggeredDiameter(selectedOption);
    }


  }
  const goBackToHomepage = () => {
    navigate('/');
};

  return (
    <>{ isdata ? "":<Helmet>
    <title>Page Not Found</title>
     </Helmet>}
      <TOPBAR isHidden={isHidden} />
      <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
      <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
      { isdata ? (<>
      <section>
        <div className="main-inner-banner">
          <div className="parformane-bar">
            <div className="container d-flex justify-content-between">
              <div>

                <ul className="p-0 m-0 listing-parmofamne">
                  <li><a href="/">Home /</a></li>
                  <li className='active-bread text-decoration-none'><a href="#">Tires</a></li>
                </ul>

              </div>

            </div>
          </div>
          <h2 className="text-center txt-main-listing">Tires</h2>
        </div>

      </section>

      <section>
        <div className="main-section-lsting wheelsPageSectioin">
          <div className="container">
            <div className='d-flex  car-selection-title'>
              <div className='vechicleName'>
                <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                  {vehicalDetails ? vehicalDetails.make_name : ""}
                  {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                  {vehicalDetails ? vehicalDetails.variant_name : ""}</strong>
              </div>
              <div className='staggeredCheckbox'>
                <div className="price-filter">
                  <ul className='p-0 m-0 list-filter'>
                    <li>
                      <input
                        type="checkbox"
                        value="2"
                        className=' tgl tgl-light'
                        onChange={handleStaggeredChange}
                        id='stagerd'
                        name="staggered"
                      />
                      <label htmlFor='stagerd' className=' tgl-btn'></label>
                    </li>
                    <li className='staggeredLi'>
                      {
                        isChecked ?
                          <span>Staggered Tires</span>
                          : <span>All Tires</span>
                      }
                    </li>
                  </ul>
                </div>


              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className={`main-outer-filter ${filterClass}`}>
                  <div className="filter-crose" id="close-bar">
                    <button className="submit" onClick={hideFilterClass}>
                      Submit
                    </button>
                    <button className="abc" onClick={hideFilterClass}>
                      <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                    </button>
                  </div>

                  
                  
                  
                    
                    {
                      isChecked ?
                      <div className="price-filter diameterFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setopenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                      Tire Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>
                    <Collapse in={openDiameter}>
                      <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.combinedRimDiameters && dataTires.filters.combinedRimDiameters.length > 0 ?
                            dataTires.filters.combinedRimDiameters.map((item, index) => (
                              <li key={"diameter" + item}>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id={"diameter" + item}
                                    name={item}
                                    value={item}
                                    className='filter-box'
                                    checked={ staggeredDiameter == item}
                                    onChange={handleStaggeredDiameterCheckboxChange}
                                  /> {item + '"'}
                                  <label htmlFor={"diameter" + item} className='custome-checkboxes'></label>

                                </label>
                              </li>
                            ))
                            : <ShimmerThumbnail height={10} rounded />
                        }
                      </ul>
                    </Collapse>
                  </div>
                  :
                  <div className="price-filter diameterFilter">
                  <h3 className="price-filter-text" role="button" onClick={() => setopenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                    Tire Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                  </h3>
                  <Collapse in={openDiameter}>
                    <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                      {

                        dataTires.filters && dataTires.filters.uniqueDiameterFirstTime && dataTires.filters.uniqueDiameterFirstTime.length > 0 ?
                          dataTires.filters.uniqueDiameterFirstTime.map((item, index) => (
                            <li key={"diameter" + item.value}>
                              <label className='label-filter'>
                                <input type="checkbox"
                                  id={"diameter" + item.value}
                                  name={item.value}
                                  value={item.value}
                                  className='filter-box'
                                  checked={diameterArray == item.value}
                                  onChange={handleDiameterCheckboxChange}
                                /> {item.value + '"'}
                                <label htmlFor={"diameter" + item.value} className='custome-checkboxes'></label>

                              </label>
                            </li>
                          ))
                          : <ShimmerThumbnail height={10} rounded />
                      }
                    </ul>
                  </Collapse>
                </div>

                  }


                  <div className="price-filter brandFilter ">
                    <>
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                        Brands <i className={openBrand ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openBrand}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          {

                            dataTires.filters && dataTires.filters.uniqueBrandFirstTime ?
                              dataTires.filters.uniqueBrandFirstTime.map((item, index) => (
                                <li key={item.brand_id}>
                                  <label className='label-filter'>
                                    <input type="checkbox"
                                      id={"Brands"+item.brand_id}
                                      name={item.brand}
                                      value={item.brand_id}
                                      className='filter-box'
                                      checked={brandArray.includes(String(item.brand_id))}
                                      onChange={handleBrandCheckboxChange}
                                    /> {item.brand}
                                    <label htmlFor={"Brands"+item.brand_id} className='custome-checkboxes'></label>

                                  </label>
                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>
                    </>
                  </div>

                  <div className="price-filter  modelFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenModel(!openModel)} aria-expanded={openModel} aria-controls="width-collapse-text">
                      Model <i className={openModel ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>

                    <Collapse in={openModel}>
                      <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueModelFirstTime ?
                            dataTires.filters.uniqueModelFirstTime.map((item, index) => (
                              <li key={item.line}>
                                <label className='label-filter'>
                                  <input
                                    type="checkbox"
                                    id={"width" + item.line}
                                    name={item.line}
                                    value={item.line}
                                    className='filter-box'
                                    checked={modelArray.includes(String(item.line))}
                                    onChange={handleModelCheckboxChange}
                                  />
                                  {item.line}
                                  <label htmlFor={"width" + item.line} className='custome-checkboxes'></label>
                                </label>

                              </li>
                            ))
                            : <ShimmerThumbnail height={10} rounded />
                        }
                      </ul>
                    </Collapse>
                  </div>

                  { !isChecked ?
                    <>
                    <div className="price-filter widthFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenWidth(!openWidth)} aria-expanded={openWidth} aria-controls="example-collapse-text">
                      Tire Height <i className={openWidth ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>
                    <Collapse in={openWidth}>
                      <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueWidthFirstTime && dataTires.filters.uniqueWidthFirstTime.length > 0 ?
                            dataTires.filters.uniqueWidthFirstTime.map((item, index) => (
                              <li key={"width" + item}>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id={"width" + item}
                                    name={item}
                                    value={item}
                                    className='filter-box'
                                    checked={widthArray.includes(String(item))}
                                    onChange={handleWidthCheckboxChange}
                                  /> {item + '"'}
                                  <label htmlFor={"width" + item} className='custome-checkboxes'></label>

                                </label>
                              </li>
                            ))
                            : <ShimmerThumbnail height={10} rounded />
                        }
                      </ul>
                    </Collapse>
                  </div>

                  <div className="price-filter ratioFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setopenRatio(!openRatio)} aria-expanded={openRatio} aria-controls="example-collapse-text">
                      Tire Ratio <i className={openRatio ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>
                    <Collapse in={openRatio}>
                      <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueratioFirstTime && dataTires.filters.uniqueratioFirstTime.length > 0 ?
                            dataTires.filters.uniqueratioFirstTime.map((item, index) => (
                              <li key={"ratio" + item}>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id={"ratio" + item}
                                    name={item}
                                    value={item}
                                    className='filter-box'
                                    checked={ratioArray.includes(String(item))}
                                    onChange={handleRatioCheckboxChange}
                                  /> {item}
                                  <label htmlFor={"ratio" + item} className='custome-checkboxes'></label>

                                </label>
                              </li>
                            ))
                            : <ShimmerThumbnail height={10} rounded />
                        }
                      </ul>
                    </Collapse>
                  </div>
                    </>
                  :""
                  }


                  









                  <div className="price-filter categoryFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenCategory(!openCategory)} aria-expanded={openCategory} aria-controls="example-collapse-text">
                      Tread Design <i className={openCategory ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>
                    <Collapse in={openCategory}>
                      <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueCategory ?
                            dataTires.filters.uniqueCategory.map((item, index) => (
                              <li key={"category" + item.category}>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id={"category" + item.category}
                                    name={item.category}
                                    value={item.category}
                                    className='filter-box'
                                    checked={categoryArray.includes(String(item.category))}
                                    onChange={handleCategoryCheckboxChange}
                                  /> {item.category}
                                  <label htmlFor={"category" + item.category} className='custome-checkboxes'></label>

                                </label>
                              </li>
                            ))
                            : ""
                        }
                      </ul>
                    </Collapse>
                  </div>


                  <div className="price-filter  loadRangeFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenLoadRating(!openLoadRating)} aria-expanded={openLoadRating} aria-controls="width-collapse-text">
                      Load Range <i className={openLoadRating ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>

                    <Collapse in={openLoadRating}>
                      <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueLoadRangeFirstTime ?
                            dataTires.filters.uniqueLoadRangeFirstTime.map((item, index) => (
                              <li key={item.load_range}>
                                <label className='label-filter'>
                                  <input
                                    type="checkbox"
                                    id={"load" + item.load_range}
                                    name={item.load_range}
                                    value={item.load_range}
                                    className='filter-box'
                                    checked={speedLoadRange.includes(String(item.load_range))}
                                    onChange={handleLoadRangeCheckboxChange}
                                  />
                                  {item.load_range}
                                  <label htmlFor={"load" + item.load_range} className='custome-checkboxes'></label>
                                </label>

                              </li>
                            ))
                            : ""
                        }
                      </ul>
                    </Collapse>
                  </div>



                  <div className="price-filter  speedRatingFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenSpeedRating(!openSpeedRating)} aria-expanded={openSpeedRating} aria-controls="width-collapse-text">
                      Speed Rating <i className={openSpeedRating ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>

                    <Collapse in={openSpeedRating}>
                      <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniqueSpeedRatingFirstTime ?
                            dataTires.filters.uniqueSpeedRatingFirstTime.map((item, index) => (
                              <li key={item.speedrating}>
                                <label className='label-filter'>
                                  <input
                                    type="checkbox"
                                    id={"speed" + item.speedrating}
                                    name={item.speedrating}
                                    value={item.speedrating}
                                    className='filter-box'
                                    checked={speedRatingArray.includes(String(item.speedrating))}
                                    onChange={handleSpeedRatingCheckboxChange}
                                  />
                                  {item.speedrating}
                                  <label htmlFor={"speed" + item.speedrating} className='custome-checkboxes'></label>
                                </label>

                              </li>
                            ))
                            : ""
                        }
                      </ul>
                    </Collapse>
                  </div>


                  <div className="price-filter  plyFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenPly(!openPly)} aria-expanded={openPly} aria-controls="width-collapse-text">
                      Ply <i className={openPly ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>

                    <Collapse in={openPly}>
                      <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniquePlyFirstTime ?
                            dataTires.filters.uniquePlyFirstTime.map((item, index) => (
                              <li key={item.ply}>
                                <label className='label-filter'>
                                  <input
                                    type="checkbox"
                                    id={"ply" + item.ply}
                                    name={item.ply}
                                    value={item.ply}
                                    className='filter-box'
                                    checked={plyArray.includes(String(item.ply))}
                                    onChange={handlePlyCheckboxChange}
                                  />
                                  {item.ply}
                                  <label htmlFor={"ply" + item.ply} className='custome-checkboxes'></label>
                                </label>

                              </li>
                            ))
                            : ""
                        }
                      </ul>
                    </Collapse>
                  </div>

                  <div className="price-filter  sideWallFilter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenSideWall(!openSideWall)} aria-expanded={openSideWall} aria-controls="width-collapse-text">
                      Sidewall <i className={openSideWall ? "fa fa-minus" : "fa fa-plus"}></i>
                    </h3>

                    <Collapse in={openSideWall}>
                      <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                        {

                          dataTires.filters && dataTires.filters.uniquesidewallFirstTime ?
                            dataTires.filters.uniquesidewallFirstTime.map((item, index) => (
                              <li key={item.sidewall}>
                                <label className='label-filter'>
                                  <input
                                    type="checkbox"
                                    id={"width" + item.sidewall}
                                    name={item.sidewall}
                                    value={item.sidewall}
                                    className='filter-box'
                                    checked={sideWallArray.includes(String(item.sidewall))}
                                    onChange={handleSideWallCheckboxChange}
                                  />
                                  {item.sidewall}
                                  <label htmlFor={"width" + item.sidewall} className='custome-checkboxes'></label>
                                </label>

                              </li>
                            ))
                            : <ShimmerThumbnail height={10} rounded />
                        }
                      </ul>
                    </Collapse>
                  </div>
                  {
                    dataTires.filters && dataTires.filters.uniqueMilageFirstTime && dataTires.filters.uniqueMilageFirstTime.length > 0 ?
                      <div className="price-filter milageRating ">
                        <>
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenMilageRating(!openMilageRating)} aria-expanded={openMilageRating} aria-controls="mileage-collapse-text">
                            Mileage Rating <i className={openMilageRating ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openMilageRating}>
                            <ul className='p-0 m-0 list-filter' id="mileage-collapse-text">
                              {

                                dataTires.filters && dataTires.filters.uniqueMilageFirstTime ?
                                  dataTires.filters.uniqueMilageFirstTime.map((item, index) => (
                                    <li key={item}>
                                      <label className='label-filter'>
                                        <input
                                          type="checkbox"
                                          id={item}
                                          name={item}
                                          value={item}
                                          className='filter-box'
                                          checked={milageRatingArray.includes(String(item))}
                                          onChange={handleMilageRatingCheckboxChange}
                                        />
                                        {item}
                                        <label htmlFor={item} className='custome-checkboxes'></label>
                                      </label>

                                    </li>
                                  ))
                                  : <ShimmerThumbnail height={10} rounded />
                              }
                            </ul>
                          </Collapse>
                        </>
                      </div>

                      : ""
                  }


                  {/* <div className="price-filter warranty ">
                    <>
                      <h3 class="price-filter-text" role="button" onClick={() => setOpenWarranty(!openWarranty)} aria-expanded={openWarranty} aria-controls="brand-collapse-text">
                        Warranty <i className={openWarranty ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openWarranty}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="warranty1"
                                name="warranty"
                                value="one"
                                className='filter-box'
                                onChange={handleWarrentyCheckboxChange}
                                checked={warranty == 'one'}
                              /> Yes
                              <label htmlFor="warranty1" className='custome-checkboxes'></label>
                            </label>
                          </li>
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="warranty2"
                                name="warranty"
                                value="zero"
                                className='filter-box'
                                onChange={handleWarrentyCheckboxChange}
                                checked={warranty == 'zero'}
                              /> No
                              <label htmlFor="warranty2" className='custome-checkboxes'></label>
                            </label>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  </div> */}







                  <div className="price-filter runFlat ">
                    <>
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenRunFlat(!openRunFlat)} aria-expanded={openRunFlat} aria-controls="brand-collapse-text">
                        Run Flat <i className={openRunFlat ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openRunFlat}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="run_flat"
                                name=""
                                value="one"
                                className='filter-box'
                                onChange={handleRunFlatCheckboxChange}
                                checked={runflat == 'one'}
                              /> Yes
                              <label htmlFor="run_flat" className='custome-checkboxes'></label>
                            </label>
                          </li>
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="run_flat2"
                                name=""
                                value="zero"
                                className='filter-box'
                                onChange={handleRunFlatCheckboxChange}
                                checked={runflat == 'zero'}
                              /> No
                              <label htmlFor="run_flat2" className='custome-checkboxes'></label>
                            </label>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  </div>

                  <div className="price-filter studdable ">
                    <>
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenStuddable(!openStuddable)} aria-expanded={openStuddable} aria-controls="brand-collapse-text">
                        Studdable <i className={openStuddable ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openStuddable}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="studdable"
                                name=""
                                value="one"
                                className='filter-box'
                                onChange={handleStuddableCheckboxChange}
                                checked={studdable == 'one'}
                              /> Yes
                              <label htmlFor="studdable" className='custome-checkboxes'></label>
                            </label>
                          </li>
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="studdable2"
                                name=""
                                value="zero"
                                className='filter-box'
                                onChange={handleStuddableCheckboxChange}
                                checked={studdable == 'zero'}
                              /> No
                              <label htmlFor="studdable2" className='custome-checkboxes'></label>
                            </label>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  </div>


                  {/* <div className="price-filter sizes ">
                    <>
                      <h3 class="price-filter-text" role="button" onClick={() => setOpenTireSizes(!openTireSizes)} aria-expanded={openTireSizes} aria-controls="brand-collapse-text">
                        Tire Sub Type <i className={openTireSizes ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openTireSizes}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="commonsizes"
                                name=""
                                value="one"
                                className='filter-box'
                                onChange={handleTireSizesCheckboxChange}
                                checked={tiresizes == 'one'}
                              /> Car/Truck
                              <label htmlFor="commonsizes" className='custome-checkboxes'></label>
                            </label>
                          </li>
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="allsizestire"
                                name=""
                                value="zero"
                                className='filter-box'
                                onChange={handleTireSizesCheckboxChange}
                                checked={tiresizes == 'zero'}
                              /> All Sizes
                              <label htmlFor="allsizestire" className='custome-checkboxes'></label>
                            </label>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  </div> */}

                  <div className="price-filter">
                    <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                      Price
                      <i
                        className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                      >

                      </i>
                    </h3>
                    {
                      minPrice !== null && maxPrice !== null ?
                        <Collapse in={openPrice}>
                          <div id='price-collapse-text'>
                            <form >
                              <div className="aside-price-range-form d-flex  m-3" >
                                <div className="form-field me-2" style={{ width: '80%' }}>

                                  <input
                                    className="form-control"
                                    type="text"
                                    id="minPrice"
                                    value={"$" + minPrice}
                                    placeholder={"$" + minPrice}
                                    autoComplete="off"
                                    maxLength="10"
                                    style={{ width: '100%' }}
                                    disabled={true}
                                  />
                                </div>
                                <div className="form-field me-2" style={{ width: '80%' }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="maxPrice"
                                    value={"$" + maxPrice}
                                    placeholder={"$" + maxPrice}
                                    autoComplete="off"
                                    maxLength="10"
                                    style={{ width: '100%' }}
                                    disabled={true}
                                  />
                                </div>

                              </div>
                            </form>

                            <div className="">
                              <Box sx={{ width: 275, margin: 'auto' }}>
                                <Slider
                                  value={[minPrice, maxPrice]}
                                  onChangeCommitted={handleChangeSlider}
                                  valueLabelDisplay="on"
                                  min={0}
                                  step={1}
                                  max={maxPriceDefault}

                                />
                              </Box>
                            </div>
                          </div>
                        </Collapse> :
                        ""}
                  </div>



                </div>
              </div>
              <div className="col-lg-9 filter-for-mobile">

                <div className='selectedFilters '>
                  {
                    diameterArray != "" ?
                    
                        <span className="span-filter-Checkbox m-1">
                          <span> {diameterArray + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDiameter(diameterArray)}></i>
                        </span>
                    
                      : ""
                  }


                  {
                    widthArray && widthArray.length > 0 ?
                      widthArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveWidth(item)}></i>
                        </span>
                      ))
                      : ""
                  }

                  {
                    brandNameArray && brandNameArray.length > 0 ?
                      brandNameArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item.id}>
                          <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                        </span>
                      ))
                      : ""
                  }

                  {
                    categoryNameArray && categoryNameArray.length > 0 ?
                      categoryNameArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item.id}>
                          <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                        </span>
                      ))
                      : ""
                  }

                  {
                    modelArray && modelArray.length > 0 ?
                      modelArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveModel(item)}></i>
                        </span>
                      ))
                      : ""
                  }

                  {

                    ratioArray && ratioArray.length > 0 ?
                      ratioArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveRatio(item)}></i>
                        </span>
                      ))
                      : ""
                  }

                  {
                    speedLoadRange && speedLoadRange.length > 0 ?
                      speedLoadRange.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveLoadRange(item)}></i>
                        </span>
                      ))

                      : ""
                  }

                  {
                    speedRatingArray && speedRatingArray.length > 0 ?
                      speedRatingArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedSpeedRating(item)}></i>
                        </span>
                      ))

                      : ""
                  }

                  {
                    plyArray && plyArray.length > 0 ?
                      plyArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedPly(item)}></i>
                        </span>
                      ))

                      : ""
                  }

                  {

                    sideWallArray && sideWallArray.length > 0 ?
                      sideWallArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedSideWall(item)}></i>
                        </span>
                      ))

                      : ""

                  }

                  {

                    milageRatingArray && milageRatingArray.length > 0 ?
                      milageRatingArray.map((item, index) => (
                        <span className="span-filter-Checkbox m-1" key={item}>
                          <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedMilageRating(item)}></i>
                        </span>
                      ))

                      : ""

                  }


                  {
                    diameterArray.length > 0 || brandNameArray.length > 0 || categoryNameArray.length > 0 || speedRatingArray.length > 0 || speedLoadRange.length > 0 || ratioArray.length > 0 || modelArray.length > 0 || categoryNameArray.length > 0 || widthArray.length > 0 || plyArray.length > 0 || sideWallArray.length > 0 || milageRatingArray.length > 0 ?

                      <span className="clearFilterStyle" onClick={clearAllFilters} >
                        <span>Clear filters</span>
                      </span>

                      :

                      ""
                  }

                </div>

                <div className="d-flex justify-content-end filters-sort-by">
                  <div className="">

                    {totalProducts > 0 ?
                      <p className="px-5">
                        Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products

                      </p>
                      : ""

                    }
                  </div>
                  <div className='stockCheckbox'>
                    <ul className='stockCheckboxUl'>
                      <li>

                        <input
                          type="checkbox"
                          value="2"
                          className=' tgl tgl-light'
                          onChange={handleChangeStockAvail}
                          id='stockChecki'
                          name="stockCheck"

                        />


                        <label htmlFor='stockChecki' className=' tgl-btn'></label>


                      </li>
                      <li className='staggeredLi'>
                        {
                          isCheckedStock ?
                            <span>In Stock Items</span>
                            : <span>All Items</span>
                        }
                      </li>
                    </ul>
                  </div>
                  <div className="">
                    <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value ={sortingValue}>
                      <option disabled >Sort By Price</option>
                      <option value="1" >Low-High</option>
                      <option value="2">High-Low</option>
                    </select>
                  </div>
                  <div className="main-headin">
                    <div className="main-filter-resposive">
                      <button className="mobile-filter-btn" onClick={showFiltersClick}> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                      </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row tiresCards'>
                  {


                    !isLoadingWheels && displayedProducts ?
                      displayedProducts && displayedProducts.length > 0 ?
                      displayedProducts.map((itm, idx) => {
                          return (<TiresItemscard data={itm} key={itm.item_id ? itm.item_id : idx} />);
                        })
                        : <>
                          <div className='container m-0 text-center'>
                            <h1>No Product Found</h1>
                            <p>Sorry, we couldn't find any products matching your search criteria.</p>
                            <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                          </div>

                        </>
                      :
                      Array.from({ length: 28 }).map((_, idx) => (
                        <div className="col-lg-3 col-6" key={idx}>
                          <a href="#">
                          </a>
                          <div className="Drivetrain-cart"><a href="">
                            <div className="engle-imge-upper">
                              {/* <button className="hover-btn">View Details</button> */}
                              <ShimmerThumbnail height={163} rounded />
                            </div>
                          </a>
                            <div className="cart-body">
                              <a href="#">
                                <h6><ShimmerButton size="sm" /></h6>
                                <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                              </a>
                              <div className="price-cart">
                                <a href="#">
                                  <div className="price">

                                  </div>
                                </a>
                                <div className="cart-btn">
                                  <a href="#">
                                  </a>
                                  <a href="#">

                                    {/* <img src={data.data.brand_logo} alt="" /> */}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      ))

                  }


                </div>
                <div className="d-flex mt-4 justify-content-center">
                  <Pagination>
                    <Pagination.First
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(1)}
                    />
                    <Pagination.Prev
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    />

                    {getPageNumbers().map((page) => (
                      <Pagination.Item
                        key={page}
                        active={currentPage === page}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}

                    <Pagination.Next
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    />
                    <Pagination.Last
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(totalPages)}
                    />
                  </Pagination>
                </div>
              </div>

            </div>
          </div>
        </div>


      </section >
      </>

      )
      : (<div className="error-screen">
      <h1><strong>404</strong></h1>
      <h2>PAGE NOT FOUND</h2>
      <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
        Go back to homepage
      </button>
      </div> )}
      <FOOTER />


    </>
  )
}
import Dashboard from "../pages/dashboard"
import CategoryPage from "../pages/category"
import Products from "../pages/products"
import Wheels from "../pages/wheels"
import Tires from "../pages/tires"
import TiresDetails from "../pages/tires-product-details"
import SearchProducts from "../pages/searchProducts"
import ProductDetails from "../pages/product-details"
import Warrenty from "../pages/staticPages/warrenty"
import TermsConditoins from "../pages/staticPages/termsConditoins"
import Brands from "../pages/brands"
import BrandsProducts from "../pages/brands/brandsProducts"
import BrandsWheels from "../pages/brands/brands-wheels"
import BrandsTires from "../pages/brands/brands-tires"
import Categories from "../pages/categories"
import CategoriesHome from "../pages/category-home-page"
import CategoriesHomeAll from "../pages/category-home-page/all-category"
import WheelsProducDetails from "../pages/wheels-product-details"
import WheelsThroughSearch from "../pages/wheels-through-search"
import WheelsSerachByVehical from "../pages/wheels-search-by-vehical"
import WheelsSerachByVehicalStaggered from "../pages/wheels-search-by-vehical/staggered"
import ItemSerachByVehical from "../pages/search-accessories-by-vehical"
import CategoryByVehicalSearch from "../pages/category-by-vehical-search"
import AboutUS from "../pages/staticPages/about"
import ContactUs from "../pages/staticPages/contactus"
import Blog from "../pages/staticPages/blog"
import BlogDetails from "../pages/staticPages/blog-details"
import AddCart from "../pages/cart"
import CategoryByBrand from "../pages/category-home-page/brand-category"
import Login from "../pages/Authentication/login"
import Register from "../pages/Authentication/register"
import ErrorPage from "../pages/errors"
import PageNotFoundPage  from "../pages/errors/404notfound"
import SearchBarDetails  from "../pages/searchProducts/searchbar-details"
import Finance from "../pages/finance"
import CustomeProducts from "../pages/custome-products"
import CustomeProductsDetails from "../pages/custome-products-details"
import ForgetPassword from "../pages/forget-password"
import ResetPassword from "../pages/forget-password/reset-password"
import TiresTabSearch from "../pages/tires/tires-tab-search"
import TiresWithVehicle from "../pages/tires/tires-with-vehicle"
import TiresWithVehicleLifted from "../pages/tires/tires-with-vehicle-lifted"
import TiresWithVehicleStaggered from "../pages/tires/tires-with-vehicle-staggered"
import TestPage from "../pages/testpage/test.js"






const homeRoutes = [
  // { path: "*", component: PageNotFoundPage },
  { path: "/", component: Dashboard },
  { path: "/category/:id", component: CategoryPage },
  { path: "/products/:id", component: Products },
  { path: "/wheels", component: Wheels },
  { path: "/wheels/:brand_id/:brand_name", component: Wheels },
  { path: "/search-products", component: SearchProducts },
  { path: "/product-details/:item_id", component: ProductDetails },
  { path: "/warranty", component: Warrenty },
  { path: "/terms-conditoins", component: TermsConditoins },
  { path: "/brands", component: Brands },
  { path: "/brands/brands-products/:id/:brandid/:brandname", component: BrandsProducts },
  { path: "/brands/brands-wheels", component: BrandsWheels },
  { path: "/categories", component: Categories },
  { path: "/categories-home/:id/:categoryName", component: CategoriesHome },
  { path: "/categories-home/all-category", component: CategoriesHomeAll },
  { path: "/wheels-product-details/:sku", component: WheelsProducDetails },
  { path: "/wheels-search", component: WheelsThroughSearch },
  { path: "/wheels-search-by-vehical", component: WheelsSerachByVehical },
  { path: "/wheels-search-by-vehical/staggered", component: WheelsSerachByVehicalStaggered },
  { path: "/search-accesories-by-vehical/:id", component: ItemSerachByVehical },
  { path: "/category-by-vehical", component: CategoryByVehicalSearch },
  { path: "/about-us", component: AboutUS },
  { path: "/contact-us", component: ContactUs },
  { path: "/add-cart", component: AddCart },
  { path: "/blog", component: Blog },
  { path: "/blog-details", component: BlogDetails },
  { path: "/categories-home/brand-category/:brand_name/:brand_id/", component: CategoryByBrand },
  { path: "/user/login", component: Login },
  { path: "/user/register", component: Register },
  { path: "/error", component: ErrorPage },
  { path: "/financing", component: Finance },
  { path: "/tires", component: Tires },
  { path: "/tires/:brand_id/:brand_name", component: Tires },
  { path: "/tires-details/:item_id", component: TiresDetails },
  { path: "/products/uncategorized/custom_products", component: CustomeProducts },
  { path: "/custom-product-details/:id", component: CustomeProductsDetails },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/tires/tires-tab-search", component: TiresTabSearch },
  { path: "/tires/tires-with-vehicle", component: TiresWithVehicle },
  { path: "/tires/tires-with-vehicle-lifted", component: TiresWithVehicleLifted },
  { path: "/tires/tires-with-vehicle-staggered", component: TiresWithVehicleStaggered },
  { path: "/search-results", component: SearchBarDetails },
  { path: "/pagenotfound", component: PageNotFoundPage },
  { path: "/testpage", component: TestPage },




];

export default homeRoutes;